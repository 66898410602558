#articlePage {
	max-width: 100%;
}
#articlePage img {
	max-width: 100%;
	height: auto;
}
#articlePage .wp-block-embed.is-type-video div {
	position: relative;
	width: 100%;
	padding-top: 56.25%;
}
#articlePage .wp-block-embed.is-type-video div iframe {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
}
#articlePage .section2 p {
	margin-bottom: 30px;
}
#articlePage figure {
	text-align: center;
}
#articlePage .section1 {
	background-blend-mode: darken;
	position: relative;
	background-position: center;
	background-size: 100%;
	min-height: 340px;
	display: flex;
	align-items: flex-end;
}
#articlePage .section1 h1 {
	font-family: Montserrat-Bold, sans-serif;
	font-size: 30px;
	color: white;
}
#articlePage .section1 .btn-link {
	color: white;
	padding: 0;
	font-size: 14px;
	height: 40px;
	line-height: 40px;
	box-shadow: none;
}
#articlePage .section2 {
	background-color: #f4f6f8;
	padding: 40px 0;
	position: relative;
	min-height: 70vh;
}

#articlePage .section2 .feedItemCon {
	margin: 40px 0 0;
}
#articlePage .section2 .btn-dark {
	margin: 60px auto;
}
#articlePage .section1 p {
	color: white;
	font-size: 14px;
	margin-bottom: 25px;
}
#articlePage .desc {
	font-family: Montserrat-Medium, sans-serif;
	font-size: 14px;
	line-height: 22px;
}
.left-arrow {
	display: inline-block;
	background: url('../../assets/icons/left-arrow.png');
	width: 12px;
	height: 12px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin: 0 4px 0 0;
}
@media (max-width: 768px) {
	#articlePage .section1 {
		background-position: top;
	}
	#articlePage .section2::before {
		content: '';
		width: 100%;
		height: 20px;
		background: #f4f6f8;
		border-radius: 30px;
		position: absolute;
		top: -10px;
	}
}

#listingPage .section1 {
	background-blend-mode: darken;
	background-image: url(../../assets/images/listingBg.png), linear-gradient(to bottom, #cf2345, #cf2345);
	position: relative;
	padding: 60px 0;
	background-position: center;
	background-size: cover;
}

#listingPage .section1 h1 {
	font-family: Montserrat-Bold, sans-serif;
	font-size: 36px;
	color: white;
	text-align: center;
	margin-bottom: 40px;
}

#listingPage .section2 {
	background-color: #f4f6f8;
	padding: 50px 0;
}

#listingPage h2 {
	font-family: Montserrat-Bold, sans-serif;
	font-size: 30px;
	margin-bottom: 30px;
}

#listingPage .radiosBlock {
	display: flex;
	flex-wrap: wrap;
}

#listingPage .catsBlock {
	margin-bottom: 50px;
	overflow: visible;
}

#listingPage .section2 .btn-dark {
	margin: 60px auto;
}

.loadingMore {
	width: 100%;
	min-height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (max-width: 768px) {
	#listingPage .section0 h1,
	#listingPage .section1 h1 {
		display: none;
	}
	#listingPage h2:first-child {
		margin-bottom: 0;
		font-size: 18px;
	}
	#listingPage .catsBlock {
		margin: 0 -15px;
		padding: 20px 15px 30px;
	}
	#listingPage .section1 {
		min-height: 160px;
		background: linear-gradient(0deg, #791d2f, #d03245);
		padding: 0 0;
	}
	#listingPage .section2 {
		display: flex;
		justify-content: center;
		overflow: hidden;
	}
	#listingPage .section2::after {
		content: '';
		width: 100%;
		height: 50px;
		background: linear-gradient(360deg, #791d2f 50%, rgb(123 35 70 / 0%) 40%);
		position: absolute;
		background-size: 100%;
		background-position: top;
		top: -25px;
		border-radius: 100%;
		z-index: 0;
	}
	#listingPage .radiosBlock {
		display: block;
	}
}

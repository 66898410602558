#feedPage .section1 {
	background-blend-mode: darken;
	background-image: linear-gradient(to bottom, #cf2345, #cf2345), url(../../assets/images/listingBg.png);
	position: relative;
	padding: 60px 0;
	background-position: center;
	background-size: cover;
}
#feedPage .section1 h1 {
	font-family: Montserrat-Bold, sans-serif;
	font-size: 36px;
	color: white;
	text-align: center;
	margin: 40px 0;
}
#feedPage .section2 {
	background-color: #f4f6f8;
	padding: 20px 0 60px;
	position: relative;
}
#feedPage .section2 .feedItemCon {
	margin: 40px 0 0;
}
#feedPage .section2 .btn-dark {
	margin: 30px auto;
}

@media (max-width: 768px) {
	#feedPage .section1 {
		background-image: linear-gradient(336deg, #3e060d 0%, #d03245 100%);
	}
	#feedPage .section2::before {
		content: '';
		width: 100%;
		height: 20px;
		background: #f4f6f8;
		border-radius: 30px;
		position: absolute;
		top: -10px;
	}
	#feedPage .section1 h1 {
		font-size: 24px;
		margin: 0;
	}
	#feedPage .section2 {
		padding: 0;
	}
	#feedPage .section2 .feedItemCon {
		margin: 20px 0 0;
	}
}

.podcastItemGroup {
	display: flex;
}
.podcastItem {
	display: block;
	width: 104px;
	height: 104px;
	margin: 0 0 30px 0;
	border-radius: 10px;
	box-shadow: 1.439px 1.389px 26.73px 0.27px rgba(15, 39, 76, 0.13);
	background-color: #ffffff;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	border: none;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	transition: all 0.6s;
}
.podcastItem:focus,
.podcastItem:hover,
.podcastItem:active {
	background-color: white;
	box-shadow: 3.871px 15.525px 26.73px 0.27px rgba(15, 39, 76, 0.15);
	transform: translateY(-8px);
}
.podcastItem img {
	opacity: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}
.podcastItemLoading {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.podcastListingContent {
	max-width: 60%;
}
.podcastListingContent div {
	margin-top: 35px;
	font-family: Montserrat-Bold, sans-serif;
	font-size: 14px;
}

@media (min-width: 768.99px) {
	.podcastItem {
		width: 179px;
		height: 179px;
	}
}

.responsive-image-container-top {
	position: relative;
	width: 100%;
	padding-bottom: 100%; /*  Aspect Ratio square */
	overflow: hidden;
	border-radius: 10px;
}

.responsive-image-top {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
	margin: 0 5px 0 5px;
}

.podcastAlauneLoading,
.podcastAlauneLoading-top {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 1000ms;
}
.podcastItemLegend {
	position: absolute;
	top: 50%;
	left: 20%;
	width: 100%;
	height: 100%;
	font-weight: bold;
	padding: 5px;
}
.podcastItemPlayBtnWrapper {
	position: absolute;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
	font-weight: bold;
	line-height: 40px;
	box-shadow: none;
}
.podcastItemPlayBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 45px;
	height: 45px;
	background-color: #ffff;
	border-radius: 50%;
	margin: 0 5px 5px 0;
	z-index: 99;
}

.podcastItemPlayBtn:hover,
.podcastItemPlayBtn:active {
	box-shadow: 3.871px 15.525px 26.73px 0.27px rgba(15, 39, 76, 0.15);
	transform: translateY(-2px);
	transition: all 0.6s;
}

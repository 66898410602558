$theme-colors: (
  "primary": #cf2345,
  "danger": red,
  "dark":#000,
  "secondary":#fff
);

@import "~bootstrap/scss/bootstrap";
.btn:focus, .btn.focus,input:focus{
  outline: none;
  box-shadow:none;
}
.btn{
  height: 54px;
  padding: 0 34px;
  border-radius: 13px;
  box-shadow: 0.9px 6.9px 17.8px 0.2px rgb(207 35 69 / 20%);
  font-family: Montserrat-Medium,sans-serif;
  line-height: 54px;
}
.btn-secondary{
  color: #cf2345;
}
.btn-info,.btn-info:focus, .btn-info:active,.btn-info:hover{
  background: white;
  color: #fdecee;
  border: 2px solid #d03245;
}
.form-control {
  font-family: 'Montserrat-Regular';
    font-size: 14px;
    min-height: 45px;
    padding: 10px 15px;
}
.form-control:focus{
  border-color: rgba(42 ,195 ,77,.8);
  box-shadow: 0 0 0 0.2rem rgba(42 ,195 ,77,.2);
}



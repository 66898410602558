#aboutPage {
	background-color: #f4f6f8;
}
#aboutPage .section1 {
	background-blend-mode: darken;
	background-image: linear-gradient(to bottom, #cf2345, #cf2345), url(../../assets/images/listingBg.png);
	position: relative;
	padding: 60px 0;
	background-position: center;
	background-size: cover;
}
#aboutPage .section1 h1 {
	font-family: Montserrat-Bold, sans-serif;
	font-size: 30px;
	color: white;
	text-align: center;
	margin: 40px 0;
}
#aboutPage .section2 {
	background-color: #fff;
	padding: 25px 0;
	box-shadow: 0px 13px 14px 0px rgb(15 39 76 / 2%);
	margin-bottom: 30px;
	transition: all 500ms;
}
.headerfixed {
	position: sticky;
	top: 0;
	z-index: 99;
	width: 100%;
}
#aboutPage .section2 .tabs {
	flex-direction: row;
	display: flex;
	justify-content: space-between;
}
#aboutPage .section2 .tab {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	cursor: pointer;
}
#aboutPage .section2 .tab img {
	height: 53px;
	transition: all 800ms;
}
#aboutPage .section2 .tab h5 {
	font-family: Montserrat-Bold, sans-serif;
	font-size: 12px;
	margin-top: 18px;
	color: black;
	transition: all 800ms;
	text-align: center;
	white-space: pre-line;
}
#aboutPage .section2 .tab::after {
	content: '';
	position: absolute;
	bottom: -25px;
	width: 0px;
	height: 4px;
	background: #cf2345;
	border-radius: 2px;
	transition: all 400ms;
}
#aboutPage .section2 .tab.active::after {
	width: 120px;
}
#aboutPage .section3 {
	padding: 50px 0;
	min-height: 70vh;
}
#aboutPage .section3 .tab-content {
	margin-bottom: 90px;
}
#aboutPage .section3 .tab-content h3 {
	font-family: Montserrat-SemiBold, sans-serif;
	font-size: 18px;
	color: black;
	margin: 30px 0 8px;
}
#aboutPage .section3 .tab-content h5 {
	font-family: Montserrat-SemiBold, sans-serif;
	font-size: 14px;
	color: black;
	margin-bottom: 30px;
}
#aboutPage .section3 .tab-content p {
	font-family: Montserrat-Medium, sans-serif;
	font-size: 14px;
	color: black;
	margin-bottom: 30px;
	white-space: pre-line;
}
#aboutPage .section3 .tab-image {
	background-color: #f3f6f8;
	width: 100%;
	height: 100%;
	min-height: 350px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

@media (max-width: 1024px) {
	#aboutPage .section2 .tab img {
		height: 40px;
	}
	#aboutPage .section2 .tab h5 {
		font-family: Montserrat-Bold, sans-serif;
		font-size: 12px;
		margin-top: 10px;
	}
	#aboutPage .section2 .tab.active::after {
		width: 60px;
	}
	#aboutPage .section3 .tab-content {
		margin-bottom: 30px;
	}
	#aboutPage .section3 {
		padding: 0;
	}
	#aboutPage .section3 .tab-content {
		padding-bottom: 20px;
		margin-bottom: 0px;
	}
}

@media (max-width: 756px) {
	#aboutPage .section2 .tab img {
		height: 25px;
	}
	#aboutPage .section2 .tab h5 {
		display: none;
	}
	#aboutPage .section2 .tab.active::after {
		width: 35px;
	}
}

.feedItemCon {
	cursor: pointer;
}
.feedItemCon:hover,
.feedItemCon:active {
	transform: translateY(-5px);
	transition: all 800ms;
	box-shadow: 1.439px 1.389px 26.73px 0.27px rgb(15 39 76 / 13%);
}
.feedItemImage {
	height: 219px;
	border-radius: 10px;
	background-color: #ffff;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
.feedItemContent {
	padding: 19px 12px;
}
.feedItemContent h4 {
	font-family: Montserrat-Bold, sans-serif;
	font-size: 18px;
	margin-bottom: 14px;
}
.feedItemContent .desc {
	font-family: Montserrat-Medium, sans-serif;
	line-height: 22px;
	color: black;
}
.feedItemContent .feedItemDate {
	color: #868686;
	margin-bottom: 14px;
	line-height: 1;
}

.radioItemCon {
	display: block;
	width: 179px;
	height: 179px;
	margin: 0 3% 30px 0;
	border-radius: 10px;
	box-shadow: 1.439px 1.389px 26.73px 0.27px rgba(15, 39, 76, 0.13);
	background-color: #ffffff;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	border: none;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	transition: all 0.6s;
}

.radioItemCon:focus,
.radioItemCon:hover,
.radioItemCon:active {
	background-color: white;
	box-shadow: 3.871px 15.525px 26.73px 0.27px rgba(15, 39, 76, 0.15);
	transform: translateY(-8px);
}

.radioItemCon img {
	opacity: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}
.radioItemLoading {
	width: 100%;
	height: 100%;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 1000ms;
}

.radioItemCon .btn-light {
	box-shadow: 0px 2px 7.92px 0.08px rgba(15, 39, 76, 0.2);
	width: 40px;
	height: 40px;
	border-radius: 50%;
	padding: 0;
	position: absolute;
	bottom: 10px;
	right: 10px;
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}
.btn-light:hover,
.btn-light:focus {
	background-color: #e2e6ea;
}
.radioItemContent {
	display: none;
}

.slick-initialized .slick-slide div div:focus-visible,
.slick-initialized .slick-slide div div:focus {
	outline: none;
	box-shadow: none;
}

@media (max-width: 768px) {
	.radioItemCon {
		width: 130px;
		height: 130px;
		margin-right: 15px;
		display: inline-block;
	}
	.radioItemContainer {
		display: flex;
		align-items: center;
	}
	.radioItemContent {
		display: block;
	}
	.radioItemCon .btn-light {
		left: 10px;
		width: 30px;
		height: 30px;
	}
	.radioItemListing {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
	}
	.radioItemListing .radioItemCon {
		width: 104px;
		height: 104px;
		margin-bottom: 0;
	}
	.radioItemListing .radioItemListingContent {
		max-width: 60%;
	}
	.radioItemListing .radioItemListingContent h2 {
		margin-bottom: 15px;
		font-family: Montserrat-Bold, sans-serif;
		font-size: 14px;
	}
	.radioItemListing .radioItemListingContent p {
		margin-bottom: 0px;
		font-family: Montserrat-Regular, sans-serif;
		font-size: 12px;
		color: #868686;
	}
	.radioItemCon:focus,
	.radioItemCon:hover,
	.radioItemCon:active {
		background-color: white;
		transform: none;
		outline: none;
	}
}

.catItemCon {
	margin: 0 0 20px 0;
	min-height: 60px;
	line-height: 1.2;
	padding: 0 10px;
	width: 98%;
}

@media (max-width: 768px) {
	.catItemCon {
		min-height: 40px;
		font-size: 14px;
	}
}

#proposPage {
	background-color: #f4f6f8;
}
#proposPage .section1 {
	background-blend-mode: darken;
	background-image: linear-gradient(to bottom, #cf2345, #cf2345), url(../../assets/images/listingBg.png);
	position: relative;
	padding: 60px 0;
	background-position: center;
	background-size: cover;
}
#proposPage .section1 h1 {
	font-family: Montserrat-Bold, sans-serif;
	font-size: 30px;
	color: white;
	text-align: center;
	margin: 40px 0;
}
.cover-img {
	width: 400px;
	margin: 0 auto;
	cursor: pointer;
}
.cover-img1 {
	width: 400px;
	margin: 0 auto;
}
.cover-img2 {
	width: 400px;
	margin: 0 auto;
}
.cover-img3 {
	width: 500px;
	margin: 0 auto;
}
.cover-img-con {
	width: 100%;
	height: 300px;
	text-align: center;
}
#proposPage .section2 {
	background-color: #fff;
	padding: 50px 0;
	box-shadow: 0px 13px 14px 0px rgb(15 39 76 / 2%);
	margin-bottom: 30px;
	transition: all 500ms;
}
#proposPage .section2 h1 {
	font-size: 28px;
	font-family: 'Montserrat-Bold', sans-serif;
	margin: 30px 0;
}
#proposPage .section2 h5 {
	font-size: 17px;
	font-family: 'Montserrat-Bold', sans-serif;
	margin-bottom: 15px;
}
#proposPage .section2 p {
	font-size: 14px;
	font-family: 'Montserrat-Medium', sans-serif;
}

@media (max-width: 1024px) {
	#proposPage .section2 .tab img {
		height: 40px;
	}
	#proposPage .section2 .tab h5 {
		font-family: Montserrat-Bold, sans-serif;
		font-size: 12px;
		margin-top: 10px;
	}
	#proposPage .section2 .tab.active::after {
		width: 60px;
	}
	#proposPage .section3 .tab-content {
		margin-bottom: 30px;
	}
	#proposPage .section3 {
		padding: 0;
	}
	#proposPage .section3 .tab-content {
		padding-bottom: 20px;
		margin-bottom: 0px;
	}
}

@media (max-width: 756px) {
	#proposPage .section2 .tab img {
		height: 25px;
	}
	#proposPage .section2 .tab h5 {
		display: none;
	}
	#proposPage .section2 .tab.active::after {
		width: 35px;
	}
}

@media (max-width: 500px) {
	.cover-img,
	.cover-img1,
	.cover-img2,
	.cover-img3 {
		width: 100%;
	}
}

#singlePage .section1 {
	background-blend-mode: darken;
	background-image: linear-gradient(to bottom, #cf2345, #cf2345);
	position: relative;
	padding-bottom: 40px;
}
#singlePage .section1 h2 {
	font-family: Montserrat-Bold, sans-serif;
	font-size: 36px;
	color: white;
}
#singlePage .section1 p {
	font-family: Montserrat-Regular, sans-serif;
	font-size: 14px;
	color: white;
}
#singlePage .section2 {
	position: relative;
	padding-top: 160px;
	overflow: hidden;
	display: flex;
	justify-content: center;
}
#singlePage .section2::after {
	content: '';
	width: 110%;
	height: 120px;
	background: linear-gradient(to bottom, #cf2345, #cf2345);
	position: absolute;
	top: -60px;
	border-radius: 100%;
	z-index: 0;
	overflow: hidden;
}
#singlePage .radioImg {
	width: 283px;
	height: 283px;
	border-radius: 10px;
	background-color: #ffffff;
	margin: 40px auto 27px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}
#singlePage .emissionsCon {
	border-radius: 10px;
	overflow: hidden;
	margin: 26px 0 80px;
	min-height: 500px;
	background-color: #f7f7f7;
}
#singlePage .emissionItem {
	width: 100%;
	padding: 25px 25px 25px 25px;
	background-color: #f7f7f7;
	border-bottom: 3px solid white;
	display: flex;
}
#singlePage .emissionItemCard {
	width: 95px;
	height: 95px;
	border-radius: 10px;
	background-color: #d2cfd3;
	display: inline-table;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 11px;
	text-align: center;
	line-height: 95px;
	vertical-align: middle;
}
#singlePage .clock {
	display: inline-block;
	background: url(../../assets/icons/clock.png);
	width: 12px;
	height: 12px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 4px;
}
#singlePage .arrow-right {
	display: inline-block;
	background: url(../../assets/icons/right-arrow.png);
	width: 10px;
	height: 10px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin: 0 4px;
}
#singlePage .emissionItemContent {
	max-width: 500px;
	margin-left: 11px;
	justify-content: space-evenly;
	display: flex;
	flex-direction: column;
}
#singlePage .emissionItemContent h5 {
	margin-bottom: 0px;
	font-family: Montserrat-Medium, sans-serif;
	font-size: 14px;
}
#singlePage .emissionItemContent h2 {
	margin-bottom: 0px;
	font-family: Montserrat-Bold, sans-serif;
	font-size: 16px;
}
#singlePage .emissionItemContent p {
	margin-bottom: 0px;
	font-family: Montserrat-Medium, sans-serif;
	font-size: 12px;
	color: #868686;
}
#singlePage .section3 {
	padding: 64px 0 60px;
	background-color: #f4f6f8;
}
#singlePage .section4 {
	padding: 0 0 60px;
}
#singlePage .radiosBlock {
	margin: 0;
}
#singlePage .title {
	text-align: center;
	font-family: Montserrat-Bold, sans-serif;
	font-size: 18px;
	color: #000;
	margin-bottom: 50px;
}
.btn-tab {
	box-shadow: none;
	height: 31px;
	padding: 0 30px;
	line-height: 31px;
	border-radius: 15px;
	background-color: #d2cfd3;
	font-family: Montserrat-Medium, sans-serif;
	font-size: 12px;
	color: #fff;
	margin-right: 6px;
}
.btn-tab:hover,
.btn-tab:active,
.btn-tab:focus,
.btn-tab.active {
	background-color: #f4cfd6;
	color: #cf2345;
	transition: all 500ms;
}
.btn-play {
	width: 116px;
	height: 116px;
	border-radius: 50%;
	box-shadow: 0px 2px 7.9px 0.1px rgba(15, 39, 76, 0.2);
	background-color: #ffffff;
	position: absolute;
	z-index: 3;
	bottom: -150px;
	margin-left: -58px;
	padding: 0;
}
.btn-play:hover,
.btn-play:active,
.btn-play:focus {
	box-shadow: 0.9px 6.9px 17.8px 0.2px rgb(207 35 69 / 20%);
	transition: all 800ms;
	transform: rotate(360deg) scale(1.1);
	background-color: #fff;
}
.btn-site,
.btn-site:hover,
.btn-site:active,
.btn-site:focus {
	height: 41px;
	line-height: 41px;
	padding: 0 31px;
	border-radius: 5px;
	background-color: #ffffff;
	color: #cf2345;
	font-family: Montserrat-Bold, sans-serif;
	font-size: 12px;
	margin-right: 10px;
}
.btn-share,
.btn-share:hover,
.btn-share:active,
.btn-share:focus {
	height: 41px;
	line-height: 41px;
	padding: 0 31px;
	border-radius: 5px;
	background-color: transparent;
	border: solid 1px #ffffff;
	color: #fff;
	font-family: Montserrat-Bold, sans-serif;
	font-size: 12px;
	margin-right: 10px;
}
.social {
	height: 41px;
	line-height: 52px;
	padding: 0 31px;
	border-radius: 5px;
	background-color: rgba(255, 255, 255, 0.39);
	display: inline-flex;
	vertical-align: middle;
	align-items: center;
}
.btn-site:hover,
.btn-site:active,
.btn-site:focus,
.social:hover,
.social:active,
.social:focus,
.btn-share:hover,
.btn-share:active,
.btn-share:focus {
	transition: all 800ms;
	transform: scale(1.05);
}
#singlePage .facebook {
	display: inline-block;
	background: url(../../assets/icons/facebook.png), white;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	background-size: 16px;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 11px;
	padding: 0;
	border: none;
}
#singlePage .twitter {
	display: inline-block;
	background: url(../../assets/icons/twitter.png), white;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	background-size: 16px;
	background-repeat: no-repeat;
	background-position: center;
	padding: 0;
	border: none;
}
#singlePage .radiosBlock {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 50px;
}

#singlePage .loading {
	min-height: 500px;
	border-radius: 10px;
	background-color: #f7f7f7;
}

@media (min-width: 769.999px) and (max-width: 1024px) {
	#singlePage .emissionItemContent {
		width: 60%;
	}
}
@media (max-width: 769px) {
	#singlePage .radioImg {
		width: 200px;
		height: 200px;
		margin: 15px auto;
	}
	#singlePage .section1 h2 {
		font-size: 30px;
	}
	.social,
	.social:hover,
	.social:active,
	.social:focus,
	.btn-site,
	.btn-site:hover,
	.btn-site:active,
	.btn-site:focus,
	.btn-share,
	.btn-share:hover,
	.btn-share:active,
	.btn-share:focus {
		padding: 0 15px;
	}
	.btn-share,
	.btn-share:hover,
	.btn-share:active,
	.btn-share:focus {
		margin-right: 0;
	}
	#singlePage .section2::after {
		height: 80px;
		top: -40px;
	}
	.btn-play {
		width: 90px;
		height: 90px;
		bottom: -125px;
		margin-left: -45px;
		background-size: 20px;
	}
	#singlePage .section2 {
		padding-top: 110px;
	}
	.days-con {
		overflow: auto;
		flex-wrap: nowrap;
		display: flex;
	}
	.days-con::-webkit-scrollbar {
		display: none;
	}
	.btn-tab {
		display: inline-block;
	}
	#singlePage .title {
		margin-bottom: 30px;
	}
	#singlePage .emissionItemCard:first-child {
		display: none;
	}
	#singlePage .emissionItem {
		padding: 15px;
	}
	#singlePage .radiosBlock {
		margin: 0 -15px;
		overflow: auto;
		white-space: nowrap;
		display: block;
		padding: 20px 15px 0;
	}
	#singlePage .emissionItemContent {
		width: 70%;
	}
}

.right-arrow-podcast-by-radio:active,
.left-arrow-podcast-by-radio:active,
.right-arrow-podcast-by-radio:focus,
.left-arrow-podcast-by-radio:focus {
	display: block;
	outline-style: none;
}
.right-arrow-podcast-by-radio:hover,
.left-arrow-podcast-by-radio:hover {
	display: block;
	outline-style: none;
	background-color: rgba(28, 26, 26, 0.5);
}
.right-arrow-podcast-by-radio {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 999;
	background-color: transparent;
	border-radius: 0 10px 10px 0;
	border: none;
}
.left-arrow-podcast-by-radio {
	position: absolute;
	top: 0;
	left: 12px;
	height: 100%;
	z-index: 999;
	background-color: transparent;
	border-radius: 10px 0 0 10px;
	border: none;
}
.overflowOption {
	overflow-x: hidden;
}

.footer {
	padding: 27px 0;
	border-radius: 10px 10px 0 0;
	background-color: #000000;
	color: white;
}
.footer p {
	margin-bottom: 0;
}
.footer .footer-link {
	display: block;
	padding: 0 10px 0;
	text-decoration: none;
	background-color: transparent;
	color: white;
	text-align: center;
}
@media (max-width: 768px) {
	.footer {
		padding: 15px 0;
		border-radius: 0;
		text-align: center;
	}
	.footer p {
		margin-bottom: 5px;
		font-size: 13px;
	}
	.footer .footer-link {
		padding: 0 5px 0 0;
		font-size: 13px;
	}
	.footer .d-flex {
		flex-direction: column;
		align-items: center;
	}
}

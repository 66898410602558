.mobileBanner {
	position: fixed;
	top: 0;
	display: flex;
	width: 100%;
	height: 60px;
	background-color: rgb(244, 244, 244);
	padding: 4px;
	font-size: 13px;
	font-weight: bolder;
	z-index: 999;
}

.mobileBannerLogo {
	width: 42px;
	border-radius: 5px;
}

.mobileBannerBtn {
	text-decoration: none;
	align-items: center;
	background-color: rgb(0, 89, 215);
	border: 0;
	border-radius: 100px;
	box-sizing: border-box;
	color: #ffffff;
	cursor: pointer;
	display: inline-flex;
	font-family: -apple-system, system-ui, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', 'Fira Sans', Ubuntu, Oxygen,
		'Oxygen Sans', Cantarell, 'Droid Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
		'Lucida Grande', Helvetica, Arial, sans-serif;
	font-weight: 600;
	justify-content: center;
	line-height: 20px;
	max-width: 480px;
	min-height: 30px;
	min-width: 0px;
	overflow: hidden;
	padding: 0px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
	touch-action: manipulation;
	transition:
		background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
		box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
		color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
	user-select: none;
	-webkit-user-select: none;
	vertical-align: middle;
}

.mobileBannerBtn:hover,
.mobileBannerBtn:focus {
	background-color: #16437e;
	color: #ffffff;
}

.mobileBannerBtn:active {
	background: #09223b;
	color: rgb(255, 255, 255, 0.7);
}

.mobileBannerBtn:disabled {
	cursor: not-allowed;
	background: rgba(0, 0, 0, 0.08);
	color: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 340px) {
	.mobileBanner {
		padding: 2px;
		font-size: 11px;
	}
}

@media screen and (max-width: 300px) {
	.mobileBanner {
		padding: 1px;
		font-size: 10px;
	}
}

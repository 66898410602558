.podcastCategory .podcastCategorie {
	width: 48%;
	min-height: 60px;
	line-height: 1.2;
	padding: 0 10px;
	margin: 1%;
}

@media (min-width: 768px) {
	.podcastCategory .podcastCategorie {
		min-height: 60px;
		font-size: 14px;
		margin: 1%;
	}
}

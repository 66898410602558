.episodeItemCard:first-child {
	display: none;
}
.episodeItem {
	padding: 15px;
	width: 100%;
	background-color: #f7f7f7;
	border-bottom: 3px solid white;
	display: flex;
}

.episodeItemCard {
	width: 95px;
	height: 95px;
	border-radius: 10px;
	background-color: #d2cfd3;
	display: inline-table;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 11px;
	text-align: center;
	line-height: 95px;
	vertical-align: middle;
}

.episodeItemContent {
	margin-left: 28px;
	width: 80%;
	justify-content: space-evenly;
	display: flex;
	flex-direction: column;
	text-overflow: hidden;
}
.episodeItemContent h5 {
	margin-bottom: 0px;
	font-family: Montserrat-Medium, sans-serif;
	font-size: 14px;
}
.episodeItemContent h2 {
	margin-bottom: 0px;
	font-family: Montserrat-Bold, sans-serif;
	font-size: 16px;
}
.episodeItemContent p {
	width: 90%;
	margin-bottom: 0px;
	font-family: Montserrat-Medium, sans-serif;
	font-size: 12px;
	color: #868686;
	text-overflow: hidden;
}

.loading {
	min-height: 500px;
	border-radius: 10px;
	background-color: #f7f7f7;
}
@media (max-width: 767.999px) {
	.btn-play-mini {
		background-color: white;
		cursor: pointer;
		border: none;
		box-shadow: none;
		height: 60px;
		width: 60px;
		border-radius: 100px;
		padding: 0;
		margin: auto;
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.btn-play-mini {
		background-color: white;
		cursor: pointer;
		border: none;
		box-shadow: none;
		height: 70px;
		width: 70px;
		border-radius: 100px;
		padding: 0;
		margin: auto;
	}
	.episodeItem {
		padding: 25px 25px 25px 25px;
	}
	.episodeItemCard {
		width: 115px;
		height: 95px;
		border-radius: 10px;
		background-color: #d2cfd3;
		display: inline-table;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		margin-right: 0px;
		text-align: center;
		line-height: 95px;
		vertical-align: middle;
	}
	.episodeItem {
		padding: 15px;
		width: 100%;
		background-color: #f7f7f7;
		border-bottom: 3px solid white;
		display: flex;
	}
}

@media (min-width: 992.999px) {
	.btn-play-mini {
		background-color: white;
		cursor: pointer;
		border: none;
		box-shadow: none;
		height: 60px;
		width: 60px;
		border-radius: 100px;
		padding: 0;
		margin: auto;
	}
	.episodeItem {
		padding: 25px 25px 25px 25px;
	}
	.episodeItemCard {
		width: 100px;
		height: 95px;
		border-radius: 10px;
		background-color: #d2cfd3;
		display: inline-table;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		margin-right: 0px;
		text-align: center;
		line-height: 95px;
		vertical-align: middle;
	}
	.episodeItem {
		padding: 15px;
		width: 100%;
		background-color: #f7f7f7;
		border-bottom: 3px solid white;
		display: flex;
	}
}

.btn-play-mini:hover,
.btn-play-mini:active,
.btn-play-mini:focus {
	box-shadow: 0.9px 6.9px 17.8px 0.2px rgb(207 35 69 / 20%);
	transition: all 800ms;
	transform: rotate(360deg) scale(1.1);
	background-color: azure;
}

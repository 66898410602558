@font-face {
	font-family: 'Montserrat-Light';
	src: url(../fonts/Montserrat-Light.ttf);
}
@font-face {
	font-family: 'Montserrat-Regular';
	src: url(../fonts/Montserrat-Regular.ttf);
}
@font-face {
	font-family: 'Montserrat-Bold';
	src: url(../fonts/Montserrat-Bold.ttf);
}

body {
	margin: 0;
	font-family: Montserrat-Medium, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 14px;
	color: black;
	overflow-x: hidden;
}

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

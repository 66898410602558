#homePage {
	background-color: #f4f6f8;
}

#homePage .section0 {
	background-size: cover;
	background-repeat: no-repeat;
	background-color: #d03245;
	background-position: center;
	background-image: url(../../assets/images/listingBg.png), linear-gradient(to bottom, #cf2345, #cf2345);
	position: relative;
	padding: 100px 0;
	display: flex;
	align-items: center;
}

#homePage .section0 h1 {
	margin: 0 0 50px;
	font-family: 'Montserrat-Bold', sans-serif;
	color: white;
	font-size: 36px;
	text-align: center;
}

#homePage .section0 h1 span {
	text-decoration: underline;
	cursor: pointer;
}
.allRadios.btn-secondary {
	display: none;
}

#homePage .section2 {
	padding-bottom: 100px;
}

#homePage .radiosBlock {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 50px;
}

#homePage .rpCard {
	width: 242px;
	height: 248px;
	border-radius: 30px;
	box-shadow: 1.4px 1.4px 26.7px 0.3px rgba(15, 39, 76, 0.13);
	background-color: #ffffff;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

#homePage .rpCardAfter {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

#homePage .rpCardAfter::after {
	content: '';
	position: absolute;
	background-image: url(../../assets/images/logoWhite.svg);
	width: 80%;
	background-repeat: no-repeat;
	height: 100%;
	background-position: center;
}

#homePage .rpCroppedView {
	margin-top: -65px;
}

#homePage h2 {
	font-size: 28px;
	font-family: 'Montserrat-Bold', sans-serif;
	white-space: pre-line;
}

#homePage .rpTextDesc {
	margin: 34px 0 47px;
	font-family: Montserrat-Medium, sans-serif;
	text-align: justify;
	word-spacing: 10px;
}

#homePage .section1 {
	background-color: white;
	padding-top: 60px;
}

#homePage .section1Title {
	margin-bottom: 25px;
}

#homePage .section05 {
	background-color: white;
	margin-bottom: 60px;
	padding-bottom: 60px;
}
#homePage .section2 .btn:focus,
#homePage .section2 .btn:active,
#homePage .section2 .btn:hover {
	transition: all 500ms;
	transform: scale(1.04);
	box-shadow: 0.9px 6.9px 17.8px 0.2px rgb(207 35 69 / 20%);
}

#homePage .section3 {
	padding: 80px 0;
	background-color: #cf2345;
	background-image: url(../../assets/images/mobileApp.png), linear-gradient(to bottom, #cf2345, #cf2345);
	background-position: center right;
	background-repeat: no-repeat;
	background-size: 70% auto;
}

.section3 .appLink {
	padding: 0;
	height: auto;
	line-height: initial;
	box-shadow: 0px 5px 26.7px 0.3px rgba(91, 35, 69, 0.41);
	border: none;
	background: transparent;
}

.section3 .appLink:focus,
.section3 .appLink:hover {
	box-shadow: 0px 5px 26.7px 0.3px rgba(91, 35, 69, 0.41);
}
.sectionTitlePlusEcoutesHome {
	cursor: pointer;
	margin-bottom: 25px;
}

#homePage .section3 h2 {
	color: #fefefe;
}

#homePage .section3 p {
	color: #fefefe;
	font-family: Montserrat-Medium, sans-serif;
	line-height: 22px;
	white-space: pre-line;
	margin: 36px 0;
}

#homePage .section3 h5 {
	color: #fefefe;
	font-family: 'Montserrat-Bold', sans-serif;
	font-size: 14px;
}

#homePage .section4 {
	background-color: white;
	padding: 54px 0;
}
.loading {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 300px;
	width: 100%;
}

.homeCats .catItemCon {
	width: 18%;
	margin-right: 2%;
}

@media (max-width: 768px) {
	#homePage .section0 {
		background: linear-gradient(0deg, #791d2f, #d03245);
		padding: 0;
	}
	#homePage .section1 {
		padding: 60px 0 20px;
		overflow: hidden;
		display: flex;
		justify-content: center;
	}
	#homePage .section1::before {
		content: '';
		width: 100%;
		height: 50px;
		background: linear-gradient(360deg, #791d2f 50%, rgb(123 35 70 / 0%) 40%);
		position: absolute;
		background-size: 100%;
		background-position: top;
		top: -25px;
		border-radius: 100%;
		z-index: 0;
	}
	#homePage .section0 h1 {
		display: none;
	}
	#homePage .radiosBlock {
		margin: 0 -15px;
		display: block;
	}
	#homePage .radiosBlock .slick-slider .slick-track {
		padding: 30px 0 20px 0;
	}
	#homePage .radiosBlock .radioItemCon {
		width: 105px;
		height: 105px;
		margin: 0 0 20px;
		float: right;
	}
	.catsBlock::-webkit-scrollbar {
		display: none;
	}
	#homePage .catsBlock {
		margin: 0 -15px;
		padding: 20px 15px 0;
	}
	#homePage .section4 {
		padding: 30px 0;
	}
	#homePage h2 {
		font-size: 20px;
		margin-bottom: 25px;
		white-space: initial;
	}
	#homePage .section3 h2 {
		font-size: 28px;
	}
	#homePage .section3 .appLink {
		margin-bottom: 60px;
	}
	#homePage .section2 {
		padding-bottom: 50px;
	}
	#homePage .rpCard {
		width: 140px;
		height: 140px;
	}
	#homePage .rpCroppedView {
		margin: 0 0 40px;
		justify-content: center;
	}
	#homePage .rpTextDesc {
		margin: 0 0 30px;
		word-spacing: 0;
	}
	.allRadios.btn-secondary {
		display: block;
		width: 100%;
		line-height: 43px;
		height: 43px;
	}
	.allRadios.btn-secondary:hover,
	.allRadios.btn-secondary:active {
		color: #cf2345;
	}
	#homePage .section1Title {
		margin-bottom: 0px;
	}
}

@media (max-width: 600px) {
	#homePage .section3 {
		padding-top: 565px;
		height: auto;
		background-color: #cf2345;
		background-image: url(../../assets/images/mobileApp.png), linear-gradient(to bottom, #cf2345, #cf2345);
		background-size: 200%;
		background-position: -400px -350px;
		background-repeat: no-repeat;
	}
	#homePage .section05 {
		margin-bottom: 60px;
		padding-bottom: 60px;
	}
}

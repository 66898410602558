.logo {
	width: auto;
	height: 42px;
}
.nav-link {
	font-weight: 600 !important;
	color: black !important;
}
.navbar {
	background-color: white;
	min-height: 86px;
	box-shadow: 1.4px 1.4px 26.7px 0.3px rgba(15, 39, 76, 0.13);
	z-index: 99;
}

@media (max-width: 768px) {
	.navbar {
		min-height: 60px;
	}
	.logo {
		height: 30px;
	}
	.navbar-nav .nav-link {
		padding: 12px 0;
		font-size: 15px;
		font-family: 'Montserrat-Bold', sans-serif;
	}
	.navbar-nav .nav-link:first-child {
		margin-top: 20px;
	}
	.navbar-nav .nav-link:last-child {
		margin-bottom: 12px;
	}
}

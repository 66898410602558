.searchCon {
	border-radius: 10px;
	padding: 2px;
	background: rgba(255, 255, 255, 0.17);
	backdrop-filter: blur(4px);
	margin: 0 40px;
	position: relative;
	box-shadow: 1.4px 1.4px 26.7px 0.3px rgb(15 39 76 / 13%);
	border: none;
	background-color: #ffffff;
	z-index: 99;
}
.searchInputCon {
	position: relative;
}
.searchInput {
	width: 100%;
	height: 75px;
	padding: 0 28px;
	border-radius: 10px;
	border: none;
	background-color: #ffffff;
}
.searchIcon {
	height: 75px;
	width: 75px;
	position: absolute;
	top: 0;
	right: 0;
	padding: 0;
	box-shadow: none;
	background: white;
}
.suggestCon {
	overflow-y: scroll;
	max-height: 320px;
	position: absolute;
	background: white;
	width: 100%;
	margin: 0 -2px;
	margin-top: -10px;
	padding: 10px 0;
	box-shadow: 0px 13px 14px 0px rgb(15 39 76 / 8%);
	border-radius: 0 0 10px 10px;
}
.moreViews {
	display: flex;
	justify-content: space-around;
	align-items: center;
	cursor: pointer;
	font-family: 'Montserrat-Bold', sans-serif;
	font-size: 0.8rem;
	font-weight: bold;
	margin-left: 35%;
	margin-top: 2%;
	width: 30%;
	height: 25px;
	background-color: #c9c9c9;
	border-radius: 5px;
}
.suggestCon::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
}

.suggestCon::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.suggestItem {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	cursor: pointer;
}
.suggestImage {
	width: 60px;
	height: 60px;
	border-radius: 10px;
	background-color: #ffffff;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	margin: 0 15px 0 25px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	display: inline-table;
}
.suggestName {
	font-family: Montserrat-Bold, sans-serif;
	line-height: 1;
	font-size: 14px;
	margin-bottom: 10px;
	text-align: left;
}
.desc {
	font-family: Montserrat-Regular, sans-serif;
	font-size: 12px;
	color: rgba(0, 0, 0, 0.6);
	text-align: left;
}
@media screen and (max-width: 768px) {
	.searchCon {
		margin: 30px 0 30px;
	}
	.searchInput {
		height: 60px;
		font-size: 16px;
	}
	.searchIcon {
		height: 60px;
	}

	.moreViews {
		display: flex;
		justify-content: space-around;
		align-items: center;
		cursor: pointer;
		font-family: 'Montserrat-Bold', sans-serif;
		font-size: 0.9rem;
		font-weight: bold;
		margin-left: 28%;
		margin-top: 2%;
		margin-bottom: 5%;
		width: 50%;
		height: 25px;
		background-color: #c9c9c9;
		border-radius: 5px;
	}
}

.playerContainer {
	width: 100%;
	position: fixed;
	bottom: -100px;
	background-color: rgba(255, 255, 255, 0.91);
	backdrop-filter: blur(4px);
	box-shadow: 0.2px -3px 15.8px 0.2px rgba(53, 35, 58, 0.06);
	z-index: 99;
	transition: all 1000ms;
}
.playerContainer .player {
	display: flex;
	padding: 10px 15px;
	align-items: center;
}
.playerContainer .image {
	width: 70px;
	height: 70px;
	border-radius: 10px;
	box-shadow: 1.4px 1.4px 26.7px 0.3px rgba(15, 39, 76, 0.13);
	background-color: #ffffff;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 15px;
}
.playerContainer .controls {
	display: flex;
	flex: 1;
	flex-direction: column;
}
.playerContainer .name {
	color: #868686;
	line-height: 1.21;
}
.playerContainer .title {
	font-family: Montserrat-Bold, sans-serif;
	line-height: 1.21;
	margin-bottom: 16px;
	font-size: 12px;
}
.playerContainer .options {
	display: flex;
}
.playerContainer .optionBtn {
	line-height: 40px;
	box-shadow: none;
	width: 42px;
	height: 42px;
	border-radius: 50%;
	padding: 0;
	margin-right: 10px;
}

.playerContainer .playPauseBtn {
	background-color: #cf2345;
	border: 2px solid #cf2345;
}

.playerContainer .rpPulseCon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: all 1500ms;
	background-color: rgba(207, 35, 69, 0.3);
	animation: pulse 2s linear infinite;
}
.playerContainer .rpPulseDot {
	display: block;
	background-color: rgba(207, 35, 69, 1);
	border: 1px solid white;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	transition: all 1s;
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.6);
	}
	100% {
		transform: scale(1);
	}
}
.playerContainer .seek {
	width: 100%;
}
.playerContainer .volumeCon {
	display: flex;
	align-items: center;
	margin-right: 44px;
}
.playerContainer .volumeBtn {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	padding: 0;
	line-height: 38px;
	box-shadow: none;
}
.minHeightPulse {
	min-height: 20px;
}
@media (max-width: 768px) {
	.playerContainer {
		bottom: -200px;
	}
	.playerContainer .player {
		flex-direction: column;
		align-items: flex-start;
	}
	.playerContainer .image {
		display: none;
	}
	.playerContainer .controls {
		flex-direction: column-reverse;
		width: 100%;
	}
	.playerContainer .seek {
		width: 70%;
	}
	.playerContainer .options {
		display: none;
	}
	.seekBar {
		padding-top: 15px;
	}
}
.podcast-player {
	display: flex;
}
.audioPlayer {
	display: flex;
	align-items: center;
	width: 90%;
}

.buttonsPlayerWrapper,
.bar {
	display: flex;
	align-items: center;
}
.forwardBackward {
	display: flex;
	align-items: center;
	background: none;
	border: none;
	line-height: 40px;
	box-shadow: none;
	width: auto;
	height: 20px;
	padding: 0;
	cursor: pointer;
}

.forwardBackward > * {
	display: flex;
	align-items: center;
	background: none;
	border: none;
	line-height: 40px;
	box-shadow: none;
	width: 20px;
	height: 20px;
	padding: 0;
	margin-right: 10px;
	cursor: pointer;
}

.forwardBackward:hover {
	color: rgba(53, 35, 58, 0.5);
}
.forwardBackward:focus {
	outline: none;
}

.playerTime {
	display: flex;
	margin-right: 15px;
	align-items: center;
}

.seekBar {
	display: flex;
}

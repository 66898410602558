@media (max-width: 768.999px) {
	#categoryPodcastPage .container {
		width: 100%;
		padding-right: 5px;
		padding-left: 5px;
		margin-right: auto;
		margin-left: auto;
	}
	.container-md,
	.container-sm,
	.container {
		max-width: 540px;
	}
	#categoryPodcastPage .section1 {
		background-blend-mode: darken;
		background-image: url(../../assets/images/listingBg.png), linear-gradient(to bottom, #cf2345, #cf2345);
		position: relative;
		padding: 60px 0;
		background-position: center;
		background-size: cover;
	}

	#categoryPodcastPage .section1 h1 {
		font-family: Montserrat-Bold, sans-serif;
		font-size: 36px;
		color: white;
		text-align: center;
		margin-bottom: 20px;
	}

	#categoryPodcastPage .section2 {
		background-color: #f4f6f8;
		padding: 50px 0;
	}

	#categoryPodcastPage h2 {
		font-family: Montserrat-Bold, sans-serif;
		font-size: 18px;
		margin-bottom: 30px;
	}
	#categoryPodcastPage .radiosBlock .slick-slider .slick-track {
		padding: 30px 0 20px 0;
	}
	#categoryPodcastPage .catsBlock {
		margin: 0 -15px;
		padding: 20px 15px 30px;
		overflow: visible;
	}

	#categoryPodcastPage .section2 .btn-dark {
		margin: 60px auto;
	}

	.loadingMore {
		width: 100%;
		min-height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	#categoryPodcastPage .podcastCategorie {
		margin: 0 10px 20px;
		min-height: 60px;
		line-height: 1.2;
		padding: 0 10px;
		width: 90%;
	}

	#categoryPodcastPag .slick-slide,
	.podcastCategorie {
		width: 90%;
	}
	.show-more-cat {
		display: flex;
		justify-content: center;
	}
	.show-more-cats-btn {
		width: 175px;
		box-shadow: none;
	}
	#categoryPodcastPage .podcastsBlock {
		display: flex;
		flex-wrap: wrap;
	}
}

@media (min-width: 769px) {
	.container-md,
	.container-sm,
	.container {
		max-width: 1110px;
	}
	#categoryPodcastPage .section1 {
		background-blend-mode: darken;
		background-image: url(../../assets/images/listingBg.png), linear-gradient(to bottom, #cf2345, #cf2345);
		position: relative;
		padding: 60px 0;
		background-position: center;
		background-size: cover;
	}

	#categoryPodcastPage .section1 h1 {
		font-family: Montserrat-Bold, sans-serif;
		font-size: 36px;
		color: white;
		text-align: center;
		margin-bottom: 20px;
	}

	#categoryPodcastPage .section2 {
		background-color: #f4f6f8;
		padding: 50px 0;
	}

	#categoryPodcastPage h2 {
		font-family: Montserrat-Bold, sans-serif;
		font-size: 30px;
		margin-bottom: 28px;
	}

	#categoryPodcastPage .section2 .btn-dark {
		margin: 60px auto;
	}

	.loadingMore {
		width: 100%;
		min-height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	#categoryPodcastPage .catsBlock-wrapper {
		margin-bottom: 50px;
		overflow: visible;
	}
	#categoryPodcastPage .catsBlock {
		box-sizing: border-box;
	}
	#categoryPodcastPage .podcastCategorie {
		margin: 0 2% 20px;
		min-height: 60px;
		line-height: 1.2;
		padding: 0 10px;
		width: 16%;
		font-size: 1rem;
	}
	#categoryPodcastPage .podcastsBlock {
		display: flex;
		flex-wrap: wrap;
	}
	.show-more-cat {
		display: flex;
		justify-content: center;
	}
	.show-more-cats-btn {
		width: 175px;
		box-shadow: none;
	}
}

#contactPage .section1 {
	background-blend-mode: darken;
	background-image: linear-gradient(to bottom, #cf2345, #cf2345), url(../../assets/images/listingBg.png);
	position: relative;
	padding: 60px 0;
	background-position: center;
	background-size: cover;
}
#contactPage .section1 h1 {
	font-family: Montserrat-Bold, sans-serif;
	font-size: 36px;
	color: white;
	text-align: center;
	margin: 40px 0;
}
#contactPage .section2 {
	background-color: #f4f6f8;
	padding: 60px 0;
}
#contactPage .section2 .section2Text {
	white-space: pre-line;
	font-family: Montserrat-Bold, sans-serif;
	font-size: 16px;
}
#contactPage h3 {
	font-family: Montserrat-Bold, sans-serif;
	font-size: 24px;
	margin-bottom: 30px;
}
#contactPage h5 {
	font-family: Montserrat-SemiBold, sans-serif;
	font-size: 18px;
	margin-bottom: 20px;
}
#contactPage p {
	white-space: pre-line;
	font-family: Montserrat-Medium, sans-serif;
	font-size: 14px;
	margin-bottom: 30px;
	line-height: 22px;
}

@media (max-width: 768px) {
	#contactPage .section1 {
		background-image: linear-gradient(336deg, #3e060d 0%, #d03245 100%);
	}
	#contactPage .section2::before {
		content: '';
		width: 100%;
		height: 20px;
		background: #f4f6f8;
		border-radius: 30px;
		position: absolute;
		top: -10px;
	}
	#contactPage .section1 h1 {
		font-size: 24px;
		margin: 0;
	}
	#contactPage .section2 {
		padding: 30px 0;
		position: relative;
	}
	#contactPage .section2 .feedItemCon {
		margin: 20px 0 0;
	}
}

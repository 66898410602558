.emptyContainer {
	min-height: 300px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.emptyContainer h4 {
	color: #868686;
}

#podcastPage {
	background-color: #f4f6f8;
}

#podcastPage h2 {
	font-size: 25px;
	font-family: 'Montserrat-Bold', sans-serif;
	white-space: pre-line;
}
#podcastPage h3 {
	font-size: 20px;
	font-family: 'Montserrat-Bold', sans-serif;
	white-space: pre-line;
}

#podcastPage p {
	font-size: 15px;
	font-family: 'Montserrat', sans-serif;
	white-space: pre-line;
}

#podcastPage .section0 {
	background-size: cover;
	background-repeat: no-repeat;
	background-color: #d03245;
	background-position: center;
	background-image: url(../../assets/images/listingBg.png), linear-gradient(to bottom, #cf2345, #cf2345);
	position: relative;
	padding: 80px 0;
	display: flex;
	align-items: center;
}

#podcastPage .section0 h1 {
	margin: 0 0 50px;
	font-family: 'Montserrat-Bold', sans-serif;
	color: white;
	font-size: 36px;
	text-align: center;
}
#podcastPage .section0 h1 span {
	text-decoration: underline;
	cursor: pointer;
}

#podcastPage .section1 {
	padding-top: 40px;
}

#podcastPage .section1 .section1Title {
	margin-bottom: 25px;
}

#podcastPage .section2 {
	padding-bottom: 30px;
}
#podcastPage .section2 .section2TitlePlusEcoutes {
	margin-bottom: 15px;
}
#podcastPage .section2 .section2Title {
	margin-bottom: 15px;
	cursor: pointer;
}
#podcastPage .section3 .section3Title {
	margin-bottom: 25px;
}
@media (max-width: 500px) {
	#podcastPage h2 {
		font-size: 20px;
	}
	#podcastPage h2 {
		font-size: 18px;
	}
}
@media (max-width: 768px) {
	#podcastPage h2 {
		font-size: 18px;
	}
	#podcastPage .section0 {
		padding: 30px 0;
	}
	#podcastPage .section1 {
		overflow: hidden;

		justify-content: center;
		margin-bottom: 30px;
	}

	#podcastPage .section4 {
		padding: 30px 0;
	}
	#podcastPage h2 {
		margin-bottom: 25px;
		white-space: initial;
	}

	#podcastPage .section3 .appLink {
		margin-bottom: 60px;
	}
	#podcastPage .section2 {
		padding-bottom: 30px;
	}

	#podcastPage .section1Title {
		margin-bottom: 0px;
	}
}

.podcastCategory {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
}

.slick-slider {
	cursor: pointer;
}

.podcastAlauneLoading {
	position: relative;
	bottom: 170px;
	width: 100%;
	height: 0px;
	/* background-color: white; */
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 1000ms;
}

.podcastAlauneLoading-top {
	position: relative;
	bottom: 50px;
	width: 100%;
	height: 100%;
	/* background-color: white; */
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 1000ms;
}

.slider-picture {
	border-radius: 10px;
	transition: all 0.6s;
}

.carousel-container {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.carousel-wrapper {
	display: flex;
	width: 100%;
	position: relative;
}

.podcastsTop-container {
	width: 100%;
}

.carousel-pict {
	cursor: pointer;
	border-radius: 10px;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

.left-arrow-alaune {
	margin-left: 8px;
	border-radius: 10px 0 0 10px;
}

.left-arrow-top,
.left-arrow-alaune {
	left: 8px;
}

.btn-show-categories {
	width: 100%;
	background-color: #d4d4d6;
	font-size: 14px;
	border-radius: 10px;
	margin-top: 5px;
	padding: 15px 0 15px 0;
	font-family: 'Montserrat-Bold', sans-serif;
	display: flex;
	justify-content: center;
	cursor: pointer;
	color: black;
	-webkit-appearance: none;
}

.btn-show-categories button {
	border: none;
	outline: none;
	background-color: #d4d4d6;
	color: black;
	-webkit-appearance: none;
}

.arrowAlauneSlider:hover {
	background-color: rgba(25, 26, 26, 0.5);
	border-bottom: 5px #f4f6f8 solid;
}

.responsive-image-container-alaune {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
	overflow: hidden;
	border-radius: 10px;
}

.responsive-image-alaune {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
	margin: 0 5px 0 5px;
}

@media (min-width: 769px) and (max-width: 811.999px) {
	#podcastPage .section0 h1 {
		font-size: 36px;
	}

	.podcastAlauneLoading,
	.podcastAlauneLoading-top {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 1000ms;
	}

	.podcastCategory .podcastCategorie {
		width: 22%;
		margin-right: 2%;
	}
}

@media (min-width: 812px) {
	#podcastPage .section0 h1 {
		font-size: 36px;
	}

	.podcastAlauneLoading {
		position: relative;
		bottom: 250px;
		width: 100%;
		height: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 1000ms;
	}

	.podcastAlauneLoading-top {
		position: relative;
		bottom: 100px;
		width: 100%;
		height: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 1000ms;
	}
	.podcastCategory .podcastCategorie {
		width: 22%;
		margin-right: 2%;
	}
}
